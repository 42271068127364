import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Last Shot Paintball
			</title>
			<meta name={"description"} content={"Üstün Paintball Deneyimi"} />
			<meta property={"og:title"} content={"Last Shot Paintball"} />
			<meta property={"og:description"} content={"Üstün Paintball Deneyimi"} />
			<meta property={"og:image"} content={"https://cosmiczephyroasis.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://cosmiczephyroasis.com/img/34570134.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://cosmiczephyroasis.com/img/34570134.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://cosmiczephyroasis.com/img/34570134.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://cosmiczephyroasis.com/img/34570134.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://cosmiczephyroasis.com/img/34570134.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://cosmiczephyroasis.com/img/34570134.jpg"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Paintball Maceraları Dünyasına Hoş Geldiniz!
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Last Shot Paintball'da, her yaştan ve beceri seviyesinden oyuncular için kapsamlı ve heyecan verici bir paintball deneyimi sunmaya kendimizi adadık. Hizmetlerimiz, her ziyaretçinin unutulmaz anılarla ve geri dönme arzusuyla ayrılmasını sağlamak için tasarlanmıştır. Titizlikle hazırlanmış oyun senaryolarından en üst düzey ekipmanlara kadar, sahada aksiyon dolu bir gün için ihtiyacınız olan her şeye sahibiz.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://cosmiczephyroasis.com/img/6.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0 40px 0"
			sm-min-height="auto"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				margin="0px"
				font="--headline2"
				color="--dark"
				max-width="850px"
				width="50%"
				md-width="100%"
				md-margin="0px 0px 16px 0px"
			>
				Oyun Senaryoları
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="50%"
				md-width="100%"
				md-margin="16px 0px 0px 0px"
				sm-margin="16px 0px 32px 0px"
			>
				Last Shot Paintball'u diğerlerinden ayıran en önemli unsurlardan biri de çok çeşitli oyun senaryolarımızdır. Her senaryo, benzersiz zorluklar ve heyecan verici bir oynanış sunmak için özenle tasarlanmıştır.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 20px 0px 20px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 30px 0px"
			>
				<Image src="https://cosmiczephyroasis.com/img/7.jpg" md-margin="0px 0px 20px 0px" />
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--base">
				Bayrağı Ele Geçir: Takımların rakibin bayrağını ele geçirmek ve üslerine geri götürmek için yarıştığı klasik bir oyun moduna katılın. Bu senaryo strateji, takım çalışması ve hızı test eder.
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--base">
					Bayrak Savunması: Oyuncuları bayrağı savunmaları için görevlendirerek bir strateji katmanı ekleyin.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base">
					Gizli Görevler: Bayrağı fark edilmeden ele geçirmek için sinsi manevraları ve sessiz indirmeleri teşvik edin.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base">
				Takım Ölüm Maçı: Takımların birbirlerini ortadan kaldırmak için yarıştığı hızlı tempolu bir mod. Ayakta kalan son takım kazanır.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base">
				Yeniden Doğma Bölgeleri: Elenen oyuncuların kısa bir gecikmeden sonra oyuna yeniden katılabilecekleri belirlenmiş alanlar.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base">
				Güçlendiriciler: Sahanın dört bir yanına dağılmış olan bu güçlendiriciler, oyunculara hız veya ateş gücü artışı gibi geçici avantajlar sağlar.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});